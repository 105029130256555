import {SEO} from 'components/SEO';
import {useI18next} from 'gatsby-plugin-react-i18next';
import React from 'react';
import Stars from '../components/Stars';
import styled from 'styled-components';
import {media, styles} from '../global.config';

const IndexSection = styled.section`
  text-align: center;
  color: #fff;
  position: relative;
`;

const IndexSectionContainer = styled.div`
  padding: 250px ${styles.containerPadding} 80px;
  position: relative;
  min-height: calc(100vh - 170px);
  margin: auto;
  max-width: ${styles.containerWidth};
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 5%;
    right: 5%;
    border-bottom: 1px solid #e5e5e5;
  }
  @media ${media.mobile} {
    padding-top: 150px;
  }
`;

const NotFound: React.FC<{}> = () => {
  const {t} = useI18next();
  const title = t('Page not found!');
  return (
    <>
      <SEO title={title} />
      <div className="index-page">
        <Stars>
          <IndexSection>
            <IndexSectionContainer>
              <h1>{title}</h1>
            </IndexSectionContainer>
          </IndexSection>
        </Stars>
      </div>
    </>
  );
};

export default NotFound;
